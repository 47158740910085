import React from 'react';
import { Navigate } from 'react-router-dom';
import { $admin, $auth, isAdmin, isForwarder, logoutAdmin, } from '../stores/admin';
import { useStore } from 'effector-react';
import { getHomeRoute } from '../stores/routes';

interface IProps {
  Component: any;
}

const AdminGuestRoute = ( { Component }: IProps ) => {
  const auth = useStore( $auth );
  const user = useStore( $admin );

  if ( !auth ) return <Component/>;

  if ( isAdmin( user ) ) return <Navigate to={ getHomeRoute( auth ) }/>;

  if ( isForwarder( user ) ) return <Navigate to='/forward/fillings'/>;

  logoutAdmin();

  return <></>;
};

export default AdminGuestRoute;
