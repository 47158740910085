import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { $admin, $auth, isForwarder } from '../stores/admin';
import { useStore } from 'effector-react';

interface IProps {
  Component: any
}

const AdminRoute = ( { Component }: IProps ) => {
  const location = useLocation();
  const auth = useStore( $auth );
  const user = useStore( $admin );

  if ( isForwarder( user ) ) {
    return <Navigate to='/login'/>;
  }

  if ( !auth ) {
    const loc = JSON.stringify( {
      path: location.pathname,
      params: location.search
    } );
    localStorage.setItem( 'before_auth_location', loc );
  }

  return auth ? <Component/> : <Navigate to='/login'/>;
};

export default AdminRoute;
