import {apiDelete, apiGet, apiPost} from '../api';
import {createEvent, createStore} from 'effector';
import {FilterParams} from '../util/filter_util';
import {IUser, IUsers, IUserToken} from '../types/users';
import {ISelectOption} from '../types/util';
import {useStore} from 'effector-react';
import {$selectedCompanyId} from './companies';
import {useMemo} from 'react';

export const types: ISelectOption[] = [{label: 'Админ', value: 0}, {label: 'Экспедитор', value: 1}];

export const updateUsers = createEvent<IUser[]>();

export const $users = createStore<IUser[]>([])
  .on(updateUsers, (state, payload) => payload);

export const useUsers = () => {
  const _users = useStore($users);
  const companyId = useStore($selectedCompanyId);

  return useMemo(() => {
    return _users.filter(f => !companyId || f.companyId === companyId);
  }, [companyId, _users.length]);
};


export const listUsers = async (filter?: FilterParams) => {
  const response = await apiGet<IUsers>({
    url: `/admin/users?${filter?.toQueryParams()}`
  });

  updateUsers(response.items || []);

  return response;
};

export const getUser = async (id?: string | number) => {
  if (!id) return;

  return await apiGet<IUser>({
    url: `/admin/users/${id}`,
  });
};

export const updateUser = async (id: string | number, data: any) => {
  return apiPost<IUser>({
    url: `/admin/users/${id}`,
    postData: data
  });
};

export const createUser = async (data: any) => {
  return apiPost<IUser>({
    url: '/admin/users',
    postData: data
  });
};

export const getUserToken = async (id: string | number) => {
  return await apiGet<IUserToken>({
    url: `/admin/users/${id}/token`,
  });
};

export const deleteUser = async (id: string | number) => {
  return apiDelete({
    url: `/admin/users/${id}`
  });
};
